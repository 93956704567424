import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';
import { isBrowser } from '~/helpers';
import { generateGuidFromString } from '~/helpers/sha1.helper';
import { GA4Login, GA4PageView } from '~/libs/ga4';
import { GA4 } from '~/libs/ga4/types/ga4';
import { useCMSPage } from '~/libs/queries/bff';
import useActiveUser from '~/libs/use-active-user';
import useUser from '~/libs/use-user';
import { MediaQuery } from '~/shared/media-query-detect';

const getDeviceType = () => {
    if (!isBrowser) {
        return 'Unknown';
    }

    const isMobile = window.matchMedia(MediaQuery.Mobile).matches;
    const isTablet = window.matchMedia(MediaQuery.Tablet).matches;
    const isDesktop = window.matchMedia(MediaQuery.Desktop).matches;
    const isLargeDesktop = window.matchMedia(MediaQuery.LargeDesktop).matches;
    const isHugeDesktop = window.matchMedia(MediaQuery.HugeDesktop).matches;

    const isDesktopLike = isDesktop || isLargeDesktop || isHugeDesktop;

    return (isMobile && 'Mobile') || (isTablet && 'Tablet') || (isDesktopLike && 'Desktop') || 'Unknown';
};

type GoogleTagManagerProps = {
    children: React.ReactNode;
};

const getPageViewEvent = (pageContent: any, locale: string, path: string) => {
    const pageViewEvent: GA4.PageViewEvent = {
        bu: locale,
        deviceType: getDeviceType(),
        ogLocation: window.location.origin + '/hjem',
        pageTitle: pageContent?.metaTitle || pageContent?.name || document.title,
        pageType: pageContent?.contentTypeAlias || '',
        pageUrl: window.document.location.href,
        pagePath: path,
    };
    return pageViewEvent;
};

const GoogleTagManager: FunctionComponent<GoogleTagManagerProps> = ({ children }) => {
    const { data: pageContent } = useCMSPage();
    const { isLoggedIn, profile } = useUser();
    const { activeProfile } = useActiveUser();
    const { locale, events, asPath } = useRouter();

    const trackPageview = (url: string) => {
        if (!url || !isBrowser) {
            return;
        }

        const pageViewEvent = getPageViewEvent(pageContent, locale as string, url);
        GA4PageView(pageViewEvent);
    };

    const getSessionData = () => {
        const pattern = (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? '_ga_3T6SGFSKF0' : '_ga_1CRT7HPV0D') + '=GS\\d.\\d.(\\d*)';
        const regex = new RegExp(pattern);
        const match = regex.exec(document.cookie);
        if (match != null && match.length > 1) {
            return match[1];
        }
        return null;
    };
    const getCountryCode = (language: string) => {
        switch (language) {
            case 'de':
                return 'DE';
            case 'fo':
                return 'FO';
            default:
                return 'DK';
        }
    };
    // Track user ID whenever user is logged in
    useEffect(() => {
        if (isLoggedIn && activeProfile && profile?.sub) {
            let coid = null;
            let sid = null;
            try {
                coid = generateGuidFromString(profile.sub);
                sid = generateGuidFromString(getSessionData() || '');
            } catch {}
            GA4Login({
                UserID: profile.sub,
                customerID: activeProfile.customer?.customerId?.toString() as string,
                customerName: activeProfile.customer?.name as string,
                customerCountry: getCountryCode(activeProfile.customer?.language as string),
                primaryDepartment: activeProfile.customer?.primaryDepartmentId as number,
                employeeID: profile.employeeId?.toString() as string,
                employeeCustomerID: profile.customer_id as string,
                employeeCustomerName: profile.customer_name as string,
                coid: coid,
                sid: sid,
            });

            const pageViewEvent = getPageViewEvent(pageContent, locale as string, asPath);
            GA4PageView(pageViewEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, activeProfile]);

    // Track pageviews for every page change
    useEffect(() => {
        // Will only run if there is a query string in the url on first render
        events.on('routeChangeComplete', trackPageview);

        return () => {
            events.off('routeChangeComplete', trackPageview);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    return <>{children}</>;
};

export default GoogleTagManager;
