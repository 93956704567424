import { useEffect } from 'react';
import { useRouter } from 'next/router';

const encodedHash = '%23';

export default function useUrlHashHandler(loaded: boolean, delay = 1000): void {
    const router = useRouter();
    const { asPath: url } = router;

    useEffect(() => {
        if (!url.includes(encodedHash) && !window.location.hash) {
            return;
        }

        if (!loaded) {
            return;
        }

        if (url.includes(encodedHash)) {
            router.replace(url.replace(encodedHash, '#'));
        }

        const { hash } = window.location;

        setTimeout(() => {
            if (hash && hash.length > 1) {
                const id = hash.substring(1);
                const element = document.querySelectorAll(`[id="${id}" i]`);
                if (element && element.length > 0) {
                    window.scrollTo({ top: element[0].getBoundingClientRect().top + window.scrollY - 175 });
                }
            }
        }, delay);
    }, [delay, loaded, router, url]);
}
