import dynamic from 'next/dynamic';
import { FunctionComponent, ReactNode } from 'react';
import { useCMSPage, useStaticContent } from '~/libs/queries/bff';
import useUrlHashHandler from '~/shared/hooks/use-url-has-handler';
import Loader from '~/shared/loader/loader.component';

const layouts = {
    root: dynamic(() => import('~/shell/layouts/root')),
    standard: dynamic(() => import('~/shell/layouts/standard')),
};

export function getLayout(type?: string) {
    switch (type) {
        // TODO: Add missing page types
        case 'P020HomePage':
        case 'P150ErrorPage':
            return layouts.root;

        // TODO: Add content page
        case 'P140NotFoundPage':
        case 'P062InternalPage':
        case 'P063ExternalPage':
            return layouts.standard;

        default:
            return layouts.standard;
    }
}

export const DynamicLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const { data: pageContent } = useCMSPage();
    const { isFetched, isSuccess } = useStaticContent();
    const nameOfPageType = pageContent?.type;
    const Layout = getLayout(nameOfPageType);

    useUrlHashHandler(isFetched && isSuccess);

    // Always wait for static content to be fetched
    if (!isFetched && !isSuccess) {
        return <Loader fixed pageTitle="Loading..." />;
    }

    return <Layout>{children}</Layout>;
};
