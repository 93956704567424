import useUser from '~/libs/use-user';
import Script from 'next/script';
import { useCallback } from 'react';

export function TidioChat() {
    const { user } = useUser();

    const handleOnLoad = useCallback(() => {
        if (!user?.profile?.user_id) {
            return;
        }

        document.tidioIdentify = {
            // distinct_id: user.profile.user_id.toString(), TODO: removed to fix multi session issue when logged in as same user
            name: user.profile.name,
            // email: user.profile.email, TODO: removed to fix multi session issue when logged in as same user
            phone: user.profile.phone,
        };

        tidioChatApi.setContactProperties({
            username: user.profile?.username,
            customer_number: user.profile?.customer_id,
            primary_department: user.profile?.belongs_to_department_id,
            address: `${user.profile?.address}, ${user.profile?.zipCodeAndCity}`,
        });
    }, [
        user?.profile?.user_id,
        user?.profile?.name,
        user?.profile?.email,
        user?.profile?.phone,
        user?.profile?.username,
        user?.profile?.customer_id,
        user?.profile?.belongs_to_department_id,
        user?.profile?.address,
        user?.profile?.zipCodeAndCity,
    ]);

    return <Script strategy="afterInteractive" src="//code.tidio.co/xbf6ph6n45xgchnfpeeynxcahqckzver.js" onLoad={handleOnLoad} />;
}
