import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { USER_INTERNAL_STATE } from '~/constants/cookie';
import { rootPage } from '~/constants/language';
import { ROUTER_QUERY_FUNCTIONS } from '~/constants/query';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { isBrowser, isServer } from '~/helpers';
import { COOKIE_JSON_REGEX } from '~/helpers/cookie.server.helper';
import { Umbraco } from '~/models/umbraco.d';
import useCookie from '~/shared/hooks/use-cookie';
import { useCMSPage, useStaticContent } from './queries/bff';
import useUser from './use-user';

export function useRedirectUser() {
    const dispatch = useLayoutDispatch();
    const { isRedirecting } = useLayoutState();
    const { data: pageContent, error, isError } = useCMSPage();
    const { data: staticContent, isFetched: isStaticContentFetched } = useStaticContent();
    const { cookieValue: internalStateCookie, updateCookie: updateInternalStateCookie } = useCookie(USER_INTERNAL_STATE);
    const { isLoggedIn, isInternalUser, user, isLoadingUser } = useUser();
    const { isReady, query, asPath, push, events, defaultLocale, locale } = useRouter();
    const isRootPage = isBrowser && asPath === rootPage;

    const startLoader = useCallback(() => {
        dispatch({
            type: LayoutActionTypes.SetIsRedirecting,
            payload: true,
        });
    }, []);

    useEffect(() => {
        function stopLoader() {
            dispatch({
                type: LayoutActionTypes.SetIsRedirecting,
                payload: false,
            });
        }

        events.on('routeChangeComplete', stopLoader);

        return () => {
            events.off('routeChangeComplete', stopLoader);
        };
    }, [dispatch]);

    const wasInternalUser = useMemo(() => {
        if (isServer) {
            return;
        }

        if (internalStateCookie?.match(COOKIE_JSON_REGEX)) {
            const parsed = JSON.parse(internalStateCookie.replace(COOKIE_JSON_REGEX, ''));
            return Boolean(parsed?.wasInternalUser);
        }

        return false;
    }, [internalStateCookie]);

    useEffect(() => {
        if (typeof user?.isInternalUser === 'undefined') {
            return;
        }

        updateInternalStateCookie(
            `j:${JSON.stringify({
                wasInternalUser: user.isInternalUser,
            })}`,
        );
    }, [updateInternalStateCookie, user?.isInternalUser]);

    useEffect(() => {
        // Early return for better readability
        function checkIfShouldRedirect() {
            // Is waiting for redirect to finish
            if (isRedirecting) {
                return;
            }

            if (isLoadingUser) {
                return;
            }

            // Anonymous page type should never redirect
            if (error?.allowAnonymousUsers) {
                return;
            }

            // All error pages should never redirect
            if (pageContent?.type && ['P140NotFoundPage', 'P150ErrorPage'].includes(pageContent.type)) {
                return;
            }

            let determinedLocale: string | undefined;

            if (user?.locale.toLowerCase() !== defaultLocale?.toLowerCase()) {
                // If the user locale matches the default locale, we should not include the locale in the URL.
                determinedLocale = user?.locale.toLowerCase();
            }

            // Logged in pages (not frontpage)
            if (isLoggedIn && !isRootPage) {
                // Any logged in pages without errors should never redirect
                if (!isError) {
                    if ([locale?.toLowerCase(), defaultLocale?.toLowerCase()].includes(user?.locale.toLowerCase())) {
                        return;
                    }

                    // Unless the users locale is not equal the current locale, or the default one.
                    return push(asPath, undefined, {
                        locale: determinedLocale,
                    });
                }

                // Any logged in pages with errors should redirect to page not found
                if (error?.allowAnonymousUsers === false) {
                    startLoader();
                    return push('/404', undefined, {
                        locale: determinedLocale,
                    });
                }
            }

            // Don't redirect since user is not logged in
            if (!isLoggedIn && isRootPage) {
                return;
            }

            // Wait for query parameters to be ready
            if (!isReady) {
                return;
            }

            // Wait for static content to be ready
            if (!isStaticContentFetched) {
                return;
            }

            // Logged in users on frontpage should always redirect to homepage
            const isInternalLogin = wasInternalUser || isInternalUser || query?.fn === ROUTER_QUERY_FUNCTIONS.internalLogin;
            const loggedInHome = isInternalLogin ? staticContent?.urlMappings?.internalHomepage : staticContent?.urlMappings?.homepage;

            if (isLoggedIn && isRootPage) {
                startLoader();

                if (!loggedInHome) {
                    console.error(`Could not redirect user. ${isInternalLogin ? 'internalHomePage' : 'homepage'} url mapping is missing.`);
                    return;
                }

                return push(loggedInHome.replace(/\/$/, ''), undefined, {
                    locale: determinedLocale,
                });
            }

            startLoader();
            window.location.href = rootPage;
        }

        checkIfShouldRedirect();
    }, [
        asPath,
        error,
        isError,
        isInternalUser,
        isLoggedIn,
        isReady,
        isRedirecting,
        isRootPage,
        isStaticContentFetched,
        pageContent,
        query,
        staticContent?.urlMappings,
        wasInternalUser,
        user,
        locale,
        defaultLocale,
        isLoadingUser,
    ]);
}
