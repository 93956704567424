import { useEffect, useState } from 'react';
import { isBrowser } from '~/helpers';
import useDebounce from '~/shared/hooks/debounce.hook';
import useEventListener from '~/shared/hooks/use-event-listener.hook';

const getViewportHeight = () => isBrowser && window.innerHeight * 0.01;
const isIOSDevice = isBrowser && navigator.platform.match(/iPhone|iPod|iPad/);

// Usage in CSS files: calc(var(--vh, 1vh) * 10) to get 10vh
const useIOSViewport = () => {
    const [height, setHeight] = useState(getViewportHeight);
    const debouncedValue = useDebounce(height, 10);

    useEffect(() => {
        if (isIOSDevice) {
            document.documentElement.style.setProperty('--vh', `${debouncedValue}px`);
        }
    }, [debouncedValue]);

    useEventListener('resize', () => {
        isIOSDevice && setHeight(getViewportHeight);
    });
};

export default useIOSViewport;
